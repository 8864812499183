<template>
  <section class="home-wineclub">
    <div class="row image-copy-split">
      <div class="columns large-6 image-col"></div>
      <div class="columns large-6 col-copy">
        <div class="small-caps-text">
          <em>The New York Times</em> Wirecutter
        </div>
        <h2>
          Wine Club Top Pick<br />
          2023 and 2024
        </h2>
        <nuxt-link to="/club/" class="button button-white">
          Find Your Club
        </nuxt-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HomeWineclubSection',
}
</script>
